<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.userInformation')"
    id="userInformation"
  >
    <div id="outer-title">{{ $t("i18n.userInformation") }}</div>
    <jl-form :columns="columns" :option="btnOption"></jl-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlForm from "../../components/form";
export default {
  name: "UserInfo",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "userName",
          label: "userName",
          type: "input",
          readonly: true,
        },
        {
          prop: "nick",
          label: "nick",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "telephone",
          label: "mobilePhone",
          type: "input",
          readonly: true,
        },
        {
          prop: "email",
          label: "email",
          type: "input",
          readonly: true,
        },
        {
          prop: "role",
          label: "role",
          type: "input",
          readonly: true,
        },
      ],
      btnOption: [
        {
          label: "edit",
          class: "primary",
          show: true,
          method: () => {
            setIsEdit(false);
            state.btnOption[0].show = false;
            state.btnOption[1].show = true;
          },
        },
        {
          label: "update",
          class: "primary",
          show: false,
          method: (row) => {
            onSubmit(row);
          },
        },
      ],
    });

    const init = async () => {
      let userId = localStorage.getItem("userId");
      let { data } = await proxy.$api.common.getUser(userId);
      state.userInfo = JSON.parse(JSON.stringify(data));
      if (data.roleDTOList) {
        data.role = data.roleDTOList[0].name;
      }
      setIsEdit(true, data);
    };

    const setIsEdit = (flag, data) => {
      state.columns.map((item, index) => {
        if (item.prop != "userName" && item.prop != "role") {
          item.readonly = flag;
        }
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          item.value = data[item.prop];
          state.columns[index].value = data[item.prop];
        }
      });
    };

    const onSubmit = async (row) => {
      for (let key in row) {
        state.userInfo[key] = row[key];
      }
      await proxy.$api.system.editUser(state.userInfo);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      setIsEdit(true);
      state.btnOption[0].show = true;
      state.btnOption[1].show = false;
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      setIsEdit,
    };
  },
};
</script>

<style lang="scss">
#userInformation {
  .form-button {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  #userInformation {
    .form-button {
      text-align: center;
    }
  }
}
</style>
